// Open or create the IndexedDB database
let db;
const request = indexedDB.open("MtesthubDB", 1);

request.onupgradeneeded = function (event) {
  db = event.target.result;
  // Create an object store for logs
  const objectStore = db.createObjectStore("internet_logs", {
    keyPath: "id",
    autoIncrement: true,
  });
  objectStore.createIndex("startTime", "startTime", { unique: false });
  objectStore.createIndex("endTime", "endTime", { unique: false });
  objectStore.createIndex("duration", "duration", {
    unique: false,
  });
  objectStore.createIndex("examId", "examId", { unique: false });
};

request.onsuccess = function (event) {
  db = event.target.result;
  console.log("Database opened successfully");
};

request.onerror = function (event) {
  console.error("Error opening database:", event.target.error);
};

// Function to log internet disconnection intervals
function logDisconnection(startTime, endTime, examId) {
  console.log("examId:", examId);
  // Validate timestamps
  if (isNaN(startTime) || isNaN(endTime)) {
    console.error("Invalid timestamps:", { startTime, endTime });
    return;
  }

  if(!examId){
    console.log("No examId provided");
    return;
  }

  const durationMilliseconds = endTime - startTime; // Calculate duration in milliseconds
  const durationSeconds = Math.floor(durationMilliseconds / 1000); // Convert to seconds
  const minutes = Math.floor(durationSeconds / 60); // Calculate minutes
  const seconds = durationSeconds % 60; // Calculate remaining seconds

  // Format duration as 'X min Y sec' or 'Y secs'
  let durationFormatted;
  if (minutes > 0) {
      durationFormatted = `${minutes} min ${seconds} sec`;
  } else {
      durationFormatted = `${seconds} secs`;
  }

  const logEntry = {
    startTime: new Date(startTime).toISOString(), // UTC format
    endTime: new Date(endTime).toISOString(), // UTC format
    duration: durationFormatted,
    examId,
  };

  const transaction = db.transaction(["internet_logs"], "readwrite");
  const objectStore = transaction.objectStore("internet_logs");
  const request = objectStore.add(logEntry);

  request.onsuccess = function (event) {
    // console.log("Log entry added successfully:", logEntry);
  };

  request.onerror = function (event) {
    console.log("Error adding log entry:", event.target.error);
  };
}

function fetchLogsByExamId(examId) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["internet_logs"], "readonly");
    const objectStore = transaction.objectStore("internet_logs");
    const index = objectStore.index("examId"); // Index by examId
    const request = index.openCursor(IDBKeyRange.only(examId));

    const logs = [];

    request.onsuccess = function (event) {
      const cursor = event.target.result;
      if (cursor) {
        logs.push(cursor.value);
        cursor.continue();
      } else {
        // console.log("Logs fetched for examId:", examId, logs);
        resolve(logs); // Resolve the Promise with the fetched logs
      }
    };

    request.onerror = function (event) {
      console.error("Error fetching logs:", event.target.error);
      reject(event.target.error); // Reject the Promise if there's an error
    };
  });
}

function deleteLogsByExamId(examId) {
  const transaction = db.transaction(["internet_logs"], "readwrite");
  const objectStore = transaction.objectStore("internet_logs");
  const index = objectStore.index("examId"); // Index by examId
  const request = index.openCursor(IDBKeyRange.only(examId));

  request.onsuccess = function (event) {
    const cursor = event.target.result;
    if (cursor) {
      // Delete the record
      objectStore.delete(cursor.primaryKey);
      cursor.continue();
    } else {
      console.log("Logs deleted for examId:", examId);
    }
  };

  request.onerror = function (event) {
    console.log("Error deleting logs:", event.target.error);
  };
}

export { logDisconnection, fetchLogsByExamId, deleteLogsByExamId };
