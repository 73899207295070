import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useRecordWebcam } from "react-record-webcam";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { CircularProgress, Typography } from "@mui/material";
import { StyledTypography } from "../../../../../utils/StyledTypographyHeadings";
import { handleopensnackbar } from "../../../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import { useDispatch } from "react-redux";

const RecordingComponent = ({
  saveFile,
  isVideoUploading,
  value,
  savedAnswer,
  questionType,
  setAlertType,
  setAlertMessage,
  setAlertSnackBarOpen
}) => {
  const dispatch = useDispatch();

  const recordWebcam = useRecordWebcam({
    frameRate: 60,
    width: "fit-content",
    height: "fit-content",
  });
  const [isRecording, setIsRecording] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(
    process.env.REACT_APP_VIDEO_RECORD_LIMIT
  );

  const [externalVideoUrl, setExternalVideoUrl] = useState("");

  const startTime = useRef(0);
  const endTime = useRef(0);

  useEffect(() => {
    if (savedAnswer) {
      setExternalVideoUrl(
        `${process.env.REACT_APP_API_URL}/uploads/${savedAnswer.replace(
          /\\/g,
          "/"
        )}`
      );
    }
  }, [savedAnswer]);

  useEffect(() => {
    if (recordWebcam.status === "PREVIEW" && recordWebcam.previewRef.current) {
      setExternalVideoUrl("");
    }
  }, [recordWebcam.status]);

  useEffect(() => {
    if (externalVideoUrl) {
      setIsPreviewMode(true); // Enter preview mode when external video URL is set
    } else if (recordWebcam.status === "PREVIEW") {
      setIsPreviewMode(true);
    } else {
      setIsPreviewMode(false);
    }
  }, [externalVideoUrl, recordWebcam.status]);

  useEffect(() => {
    let timer;

    if (isRecording) {
      timer = setInterval(() => {
        setSecondsLeft((prev) => {
          if (prev === 0) {
            stopRecording();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      clearInterval(timer);
      setSecondsLeft(process.env.REACT_APP_VIDEO_RECORD_LIMIT);
    }

    return () => clearInterval(timer);
  }, [isRecording]);

  useEffect(() => {
    if (recordWebcam.status === "OPEN" && isRecording) {
      recordWebcam.start(); // Start recording after the camera is opened
    }
  }, [recordWebcam.status, isRecording, recordWebcam]);

  const startRecording = async () => {
    try {
      await recordWebcam.open();
      startTime.current = Date.now();
      setIsRecording(true);
      setIsRecorded(false);
    } catch (error) {
      console.error("Error opening camera:", error);
    }
  };

  const stopRecording = () => {
    recordWebcam.stop();
    endTime.current = Date.now();
    setIsRecording(false);
    setIsRecorded(true);
    setSecondsLeft(process.env.REACT_APP_VIDEO_RECORD_LIMIT); // Reset the timer when recording stops
  };

  // const handleSaveRecording = async () => {
  //   const blob = await recordWebcam.getRecording();
  //   await saveFile(blob);
  //   setIsRecorded(false);
  // };
  const handleSaveRecording = async () => {
    const blob = await recordWebcam.getRecording();
    const duration = (endTime.current - startTime.current);

    console.log("Manual Duration:", duration);

    console.log("threshold value setted", Number(process.env.REACT_APP_AI_VIDEO_QUESTION_LENGTH));
    
    console.log("checking condition", Number(questionType) === 9 && duration < Number(process.env.REACT_APP_AI_VIDEO_QUESTION_LENGTH));
    if (Number(questionType) === 9 && duration < Number(process.env.REACT_APP_AI_VIDEO_QUESTION_LENGTH)) {
      console.log("inside condition");
      setAlertType("error");
      setAlertMessage("The recorded video is too short. Please record a video of at least 40 seconds for the answer to be valid.");
      setAlertSnackBarOpen(true);
      return;
    }
   
    console.log("reached herer")
    // Save the file if validation passes
    await saveFile(blob);
    setIsRecorded(false);
  };

  return (
    <div>
      <p>Camera status: {isPreviewMode ? "PREVIEW" : recordWebcam.status}</p>
      {recordWebcam.status === "ERROR" ? (
        <p style={{ color: "red" }}>
          Please allow camera and microphone access for recording from the
          browser site settings
        </p>
      ) : null}

      {/* Display the live webcam preview */}
      <div className="mb-4">
        {recordWebcam.status === "INIT" ? <CircularProgress /> : null}
        <video
          ref={recordWebcam.webcamRef}
          autoPlay
          muted
          controlsList="nodownload"
          style={
            isRecording && recordWebcam.status !== "ERROR"
              ? { display: "block", width: "24vw" }
              : { display: "none" }
          }
        />
        <video
          ref={recordWebcam.previewRef}
          style={
            isPreviewMode
              ? { display: "block", width: "24vw" }
              : { display: "none" }
          }
          muted
          controls
          controlsList="nodownload"
          src={externalVideoUrl || undefined}
          {...(externalVideoUrl && { crossOrigin: "anonymous" })}
        />
        {isRecording && (
          <div
            style={{
              width: "24vw",
              display: " flex",
              justifyContent: "flex-end",
            }}
          >
            <StyledTypography
              variant="h5"
              sx={{ fontWeight: 800 }}
            >{`00:${String(secondsLeft).padStart(2, "0")}`}</StyledTypography>
          </div>
        )}
      </div>

      <Button
        sx={{
          backgroundColor: "#1f219c",
          color: "#fff",
          "&:hover": { backgroundColor: "#1f219c", opacity: 0.7 },
        }}
        onClick={
          isRecording && recordWebcam.status !== "ERROR"
            ? stopRecording
            : startRecording
        }
      >
        <RadioButtonCheckedIcon style={{ marginLeft: "4px" }} />
        <StyledTypography variant="body1">
          {isRecording && recordWebcam.status !== "ERROR"
            ? "Stop Recording"
            : isPreviewMode
            ? "Re-record again"
            : "Start Recording"}
        </StyledTypography>
      </Button>

      {isPreviewMode && isRecorded ? (
        <Button
          sx={{
            backgroundColor: "#1f219c",
            color: "#fff",
            ml: 2,
            "&:hover": { backgroundColor: "#1f219c", opacity: 0.7 },
          }}
          className="saveRecordingButton"
          onClick={handleSaveRecording}
        >
          <SaveIcon />
          <StyledTypography variant="body1">
            Save Recording
            {isVideoUploading ? (
              <>
                {/* show loader here using bootstrap */}
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : null}
          </StyledTypography>
        </Button>
      ) : null}
    </div>
  );
};

export default RecordingComponent;
