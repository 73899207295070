import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Redux/hooks";
import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import AntiCheatTermsModal from "../modals/AntiCheatTermsModal";
import { StyledTypography } from "../../../../utils/StyledTypographyHeadings";
import { handleopensnackbar } from "../../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import PermissionMessage from "./Permissions_Allow/PermissionMessage";

type OtherPermissionsType = {
  setShowTermsComp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOtherComp: React.Dispatch<React.SetStateAction<boolean>>;
  testid?: string;
  tokenid?: string;
  fullscreentogglehandler: () => void;
}

const OtherPermissions = ({
  setShowOtherComp,
  setShowTermsComp,
  testid,
  tokenid,
  fullscreentogglehandler,
}: OtherPermissionsType) => {
  const [modalConfig, setModalConfig] = React.useState({
    type: 0,
    showModal: false,
  });

  const handleShowModal = (type:number) => {
    setModalConfig({
      type: type,
      showModal: true,
    });
  };

  // const [notificationPermission, setNotificationPermission] =
  //   React.useState<any>(null);

  const [locationPermission, setLocationPermission] = React.useState<boolean>(false);

  // const requestNotificationPermission = () => {
  //   if ("Notification" in window && Notification.permission !== "granted") {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === "granted") {
  //         setNotificationPermission(true);
  //       } else {
  //         setNotificationPermission(false);
  //       }
  //     });
  //   } else if (Notification.permission === "granted") {
  //     setNotificationPermission(true);
  //   } else {
  //     setNotificationPermission(false);
  //   }
  // };

  var datapayload = {
    messages:
      "Location access is blocked. Please turn on location or unblock it in your browser settings.",
    severity: "error",
  };
  
  React.useEffect(() => {
    // Check if location permission was already granted
    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          setLocationPermission(true);
        } else if (result.state === "denied") {
          dispatch(handleopensnackbar(datapayload));
        }
      });
    }
  }, []);

  const requestLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationPermission(true);
          console.log("Location granted:", position);
        },
        (error) => {
          console.error("Error getting location:", error.message);
          setLocationPermission(false);
          if (error.code === error.PERMISSION_DENIED) {
            dispatch(handleopensnackbar(datapayload));
          }
        }
      );
    }
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  const handleNext = () => {
    console.log("Navigating to test page...");
    if (tokenid) {  
      navigate(`/preview-test/${tokenid}`);
    }else if (testid) {
      navigate(`/test/${testid}`);
    }
    if (fetchedoverviewdata?.setting?.fullscreen === "true") {
      fullscreentogglehandler();
    }
    setShowOtherComp(false);
    setShowTermsComp(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          padding: 2,
          width: '100%'
        }}
      >
        <Box
          component="img"
          src={toAbsoluteUrl("/media/share_location.png")}
          alt="camera-required-image"
          sx={{
            width: {xs: 280, sm: 500},
            height: 250,
            objectFit: "contain",
          }}
        />

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-start",
            alignItems: "center",
            ml: 1,
          }}
        >
          <button
            className="prerequisite-buttons"
            onClick={requestLocationPermission}
          >
            Click to allow location access
            <LocationOnIcon sx={{ fontSize: 18 }} />
          </button>
          {locationPermission ? (
            <IoMdCheckmark size={20} color="green" />
          ) : (
            <IoMdClose size={20} color="red" />
          )}
        </Box>
        <StyledTypography variant="body1" sx={{ fontSize: { xs: 10, sm: 16, xl: 18 } }}>
          By clicking on above button you agree the
          <StyledTypography
            component="span"
            variant="body1"
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              marginLeft: 1,
              fontSize: { xs: 10, sm: 16, xl: 18 }
            }}
            onClick={() => handleShowModal(4)}
          >
            terms and conditions for location sharing
          </StyledTypography>
        </StyledTypography>

        {/* <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-start",
            alignItems: "center",
            ml: 1,
          }}
        >
          <button
            className="prerequisite-buttons"
            onClick={requestNotificationPermission}
          >
            Click to allow notificaitons
            <NotificationsActiveIcon sx={{ fontSize: 18 }} />
          </button>
          {notificationPermission === true ? (
            <IoMdCheckmark size={20} color="green" />
          ) : (
            <IoMdClose size={20} color="red" />
          )}
        </Box>
        <StyledTypography variant="body1" sx={{ fontWeight: 600 }}>
          By clicking on above button you agree the
          <StyledTypography
            component="span"
            variant="body1"
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              marginLeft: 1,
            }}
            onClick={() => handleShowModal(5)}
          >
            terms and conditions for notification sharing
          </StyledTypography>
        </StyledTypography> */}

        <PermissionMessage location={true}/>

        <button
          className={`styled-btn ${
            locationPermission ? "" : "disabled"
          }`}
          onClick={handleNext}
          disabled={locationPermission === false ? true : false}
        >
          Start Test
        </button>
      </Box>

      <AntiCheatTermsModal
        type={modalConfig.type}
        showModal={modalConfig.showModal}
        setShowModal={() => {
          setModalConfig((prev) => ({ ...prev, showModal: false }));
        }}
      />
    </>
  );
};

export default OtherPermissions;
