import { createSlice } from "@reduxjs/toolkit";
import { RecordRTCPromisesHandler } from "recordrtc";
import { useAppSelector } from "../hooks";
import axios from "axios";

const initialState = {
  recorder: null,
  stream: null,  
};

export const ScreenRecord = createSlice({
  name: "ScreenRecordslice",
  initialState,
  reducers: {
    handlestartrecording: (state, action) => {
      state.recorder = action.payload.recordinstance;
    },
    handlestartrecordingforstream: (state, action) => {
      state.stream = action.payload.streaminstance;
    },
    handlestoprecording: (state) => {
      state.recorder = null;
      state.stream = null;
    },
  },
});

export const { handlestartrecording, handlestartrecordingforstream, handlestoprecording } =
  ScreenRecord.actions;
export default ScreenRecord.reducer;
