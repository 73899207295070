import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import { HeadingsTypography, StyledTypography } from "../../../../utils/StyledTypographyHeadings";

const TermsCheckData = [
  {
    id: "cd6254a3-1a64-4feb-b9a6-8e2d3e39cec0",
    title: "Screen and Audio Recording",
    identifier: "screen_audio",
    label:
      "Your screen and audio will be recorded during the test. This recording is solely for monitoring purposes to ensure the integrity of the assessment.",
  },
  {
    id: "3395b213-4e5c-49fe-a0c7-b8e8d1a1f948",
    title: "Image Capture",
    identifier: "image_capture",
    label:
      "We may capture images of you at intervals throughout the test for identity verification and security purposes.",
  },
  {
    id: "04d26589-27bc-4553-8c26-43537c67428e",
    title: "Live Monitoring",
    identifier: "live_monitoring",
    label:
      "A proctor may monitor your camera, screen, and audio in real-time during the test to uphold test security.",
  },
  {
    id: "b53f3d40-dce1-4627-a9dc-3ffed5179c7e",
    title: "Video Answer Recording",
    identifier: "video_answer",
    label:
      "We require access to your camera to record video responses during the test for security and submission purposes.",
  },
  {
    id: "e39ec189-82d4-4147-8e55-47026c842660",
    title: "Stay on the Test Screen",
    identifier: "tab_switch",
    label:
      "You are required to remain on the test screen at all times. Switching to other tabs or windows during the test is not permitted.",
  },
  {
    id: "d482ca53-cbf3-4d79-b1b5-6ceb5cef597f",
    title: "Full Screen",
    identifier: "full_screen",
    label:
      "The test must be taken in full-screen mode and should remain as such until you have completed the assessment.",
  },
  {
    id: "c52b4222-8bcb-44f9-b076-5033400649e1",
    title: "Location and Technical Information",
    identifier: "location_technical_info",
    label:
      "The system will collect your browser’s location, IP address, and other technical details, such as browser version, operating system, and more, to maintain the security of the testing environment.",
  },
  {
    id: "ac0a2b94-bcb9-4d00-b7fb-c89734db49f8",
    title: "Notification",
    identifier: "notification",
    label:
      "You agree to enable browser notifications to receive important messages from the system or proctor during the assessment. These notifications will be standard browser alerts, not push notifications.",
  },
  
];

type AgreeTermsType = {
  setShowTermsComp: React.Dispatch<React.SetStateAction<boolean>>;
  setCameraComp: React.Dispatch<React.SetStateAction<boolean>>;
  setScreenComp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOtherComp: React.Dispatch<React.SetStateAction<boolean>>;
  testid?: string;
  tokenid?: string;
  fullscreentogglehandler: () => void;

}

const AgreeTerms = ({
  setShowTermsComp,
  setCameraComp,
  setScreenComp,
  setShowOtherComp,
  testid,
  tokenid,
  fullscreentogglehandler,
}: AgreeTermsType ) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );
  const showCameraTerm =
    fetchedoverviewdata?.setting?.randomphotos == "true" &&
      fetchedoverviewdata?.setting?.anti_cheating == "true";

  const showVideoAnswerTerm = fetchedoverviewdata?.isvideoanswer ? true : false;

  const showScreenAndAudioTerm =
    fetchedoverviewdata?.setting?.anti_cheating == "true" &&
    fetchedoverviewdata?.setting?.allowscreenrecording == "true";

  const LiveMonitoringTerm =
    fetchedoverviewdata?.setting?.live_proctoring_camera == "true" ||
    fetchedoverviewdata?.setting?.live_proctoring_screen == "true";

  const SwitchTabsTerm =
    fetchedoverviewdata?.setting?.anti_cheating == "true" &&
    fetchedoverviewdata?.setting?.no_switch_tabs_allowed == "true";

  const FullScreenTerm =
    fetchedoverviewdata?.setting?.anti_cheating == "true" &&
    fetchedoverviewdata?.setting?.fullscreen == "true";

  const LocationTerm =
    fetchedoverviewdata?.setting?.anti_cheating == "true" &&
    fetchedoverviewdata?.setting?.islocation == "true";

  const handleNext = () => {
    if (
      showCameraTerm ||
      showVideoAnswerTerm ||
      fetchedoverviewdata?.setting?.live_proctoring_camera == "true"
    ) {
      setCameraComp(true);
      setShowTermsComp(false);
    } else if (
      !showCameraTerm &&
      (showScreenAndAudioTerm ||
        fetchedoverviewdata?.setting?.live_proctoring_screen == "true")
    ) {
      setScreenComp(true);
      setShowTermsComp(false);
    } else if(fetchedoverviewdata?.setting?.islocation == "true"){
      setShowTermsComp(false);
      setShowOtherComp(true);
    }else {
      console.log("Navigating to test page...");
      if (tokenid) {  
        navigate(`/preview-test/${tokenid}`);
      }else if (testid) {
        navigate(`/test/${testid}`);
      }
      if (fetchedoverviewdata?.setting?.fullscreen === "true") {
        fullscreentogglehandler();
      }
    }
  };

  return (
    <Box
      sx={{
        paddingX: {xs: 2, sm: 8},
        paddingY: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <HeadingsTypography variant="h4" sx={{ mt: 2 }}>
        Rules You Need to Agree to proceed further with test:
      </HeadingsTypography>
      <ul>
        {TermsCheckData.map((item, index) => (
          <li
            key={index}
            style={{
              display:
                item?.identifier === "image_capture" && showCameraTerm
                  ? "list-item"
                  : item?.identifier === "screen_audio" &&
                    showScreenAndAudioTerm
                  ? "list-item"
                  : item?.identifier === "live_monitoring" && LiveMonitoringTerm
                  ? "list-item"
                  : item?.identifier === "tab_switch" && SwitchTabsTerm
                  ? "list-item"
                  : item?.identifier === "full_screen" && FullScreenTerm
                  ? "list-item"
                  : item?.identifier === "location_technical_info" && LocationTerm
                  ? "list-item"
                  : item?.identifier === "notification"
                  ? "list-item"
                  : item?.identifier === "video_answer" && showVideoAnswerTerm
                  ? "list-item"
                  : "none",
              margin: "15px 0px 0px 0px",
              listStyleType: "disc",
            }}
          >
            <StyledTypography
              variant="body1"
              sx={{ fontSize: { xs: 12, sm: 14, lg: 16, xl: 18 } }}
            >
              <StyledTypography
                component="span"
                sx={{
                  fontSize: { xs: 12, sm: 14, lg: 16, xl: 18 },
                  fontWeight: 800,
                }}
              >
                {item?.title}:&nbsp;
              </StyledTypography>
              {item?.label}
            </StyledTypography>
          </li>
        ))}
      </ul>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <HeadingsTypography
          variant="h2"
          sx={{
            
          }}
        >
          Agreement:
        </HeadingsTypography>

        <StyledTypography
          variant="body1"
          sx={{ fontSize: { xs: 12, sm: 14, lg: 16, xl: 18 } }}
        >
          By starting this test, you agree to these rules. Breaking any rule may
          disqualify you from the test.
        </StyledTypography>

        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              name="checked"
              onChange={() => {
                setIsChecked(!isChecked);
                sessionStorage.setItem(
                  "user_preference_agree_terms",
                  (!isChecked).toString()
                );
              }}
            />
          }
          label={
            <StyledTypography
              variant="body1"
            >
              I Agree with all above terms and want to proceed
            </StyledTypography>
          }
        />

        <button
          className={`styled-btn ${!isChecked ? "disabled" : ""}`}
          onClick={handleNext}
          disabled={!isChecked && fetchedoverviewdata?.allowed_to_take_test}
        >
          Next
        </button>
      </Box>
    </Box>
  );
};

export default AgreeTerms;
