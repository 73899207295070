import { Rating, Typography } from "@mui/material";
import axios from "axios";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import "./Modalfortest.css"

type Props = {
  show: boolean;
  handleClose: () => void;
  title: String;
  remarks: number;
  score: number;
  remarks_description: string;
  resultstatus: number;
  rating: number;
};

const Modalforremarks = (props: Props) => {
  const {
    show,
    handleClose,
    title,
    remarks,
    score,
    remarks_description,
    resultstatus,
    rating,
  } = props;

  // alert(remarks);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "100vw !important" }}>
          <Typography variant="h6" dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(remarks_description),
          }} style={{ padding: "40px" }}>


          </Typography>

          <Modal.Footer className="modalfooterparent">
            {
              resultstatus === 1 && score != null && <p style={{ marginRight: "-20px", fontSize: "14px" }}>
              <span style={{ fontWeight: "bold" }}>
                Total Marks:{" "}
              </span>
              {score}
            </p>
            }

            {remarks === 1 ? (
              <p className="bottomfootertext">
                <span style={{ fontWeight: "bold" }}>Remarks:</span>
                <span style={{ color: "green" }}> Passed </span>
              </p>
            ) : (
              <p className="bottomfootertext">
                <span style={{ fontWeight: "bold" }}>Remarks:</span>
                <span style={{ color: "red" }}> Failed </span>
              </p>
            )}
          </Modal.Footer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}

        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              borderBottom: "none",
            }}
          >
            <h2 className="modal-title font-black">{title}</h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="p-3 bg-light rounded">
              <p
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(remarks_description),
                }}
              ></p>
            </div>
          </div>
          <div
            className="modal-footer d-flex justify-content-between align-items-center"
            style={{
              borderTop: "none",
            }}
          >
            {resultstatus === 1 && score != null && (
              <p style={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "bold", margin: "15px 0" }}>
                  Total Marks:{" "}
                </span>
                {score}
              </p>
            )}
            <div>
              <span
                className="badge"
                style={{
                  fontSize: "14px",
                  padding: "8px",
                  backgroundColor: "#E5F4FF",
                  color: remarks === 1 ? "#01DA01" : "#fc3030",
                }}
              >
                {remarks === 1 ? "Passed" : "Failed"}
              </span>
            </div>
            <Rating
              name="half-rating-read"
              defaultValue={rating ?? 0}
              precision={0.5}
              readOnly
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Modalforremarks;
