/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosResponse } from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  CardsWidget17,
  CardsWidget20,
  CardsWidget7,
  Questioncardwidget,
} from "../../../_metronic/partials/widgets";
import { answeredvalues, fetchdata } from "../../../Redux/TestPage/TestSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Button } from "react-bootstrap";
import { Online, Offline } from "react-detect-offline";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import SubmitModal from "./SubmitModal";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../Redux/Loader/Loader";
import { CameraModal } from "../../modules/auth/components/CameraModal";
import WebcamCapture from "../../modules/auth/components/MainCamera";
// import Camerafortest from "./Camerfortest/Camerafortest";
import { handlelocationpermission } from "../../../Redux/Checkpermissions/Permission";
import {
  fetchdashboard,
  fetchsubmittedtest,
} from "../../../Redux/Dashboard/Testseries";
import { CardsWidget21 } from "../../../_metronic/partials/widgets/_new/cards/CardsWidget21";
import SubmittedFilter from "./SubmittedFilter/SubmittedFilter";
import "../dashboard/Dashboard.css";
import FeedbackForm from "./feedbackModal";
var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
type Props = {};

const Testpage = (props: Props) => {
  const [fetchdata, setfetcheddata] = useState<any>({
    result: [],
    success: "",
  });

  const dispatch = useAppDispatch();
  const payload = {
    type: "all",
  };
  React.useEffect(() => {
    dispatch(fetchsubmittedtest(payload));
  }, []);

  const fetcheddata = useAppSelector(
    (state) => state.testseries.fetcheddatasubmitted
  );

  useEffect(() => {
    setfetcheddata(fetcheddata);
  }, [fetcheddata]);



  const searchParams = new URLSearchParams(document.location.search);

  useEffect(() => {
    if (

      searchParams.get('isfeedback')
    ) {
      handleshow();
    }
  }, [])


  const [show, setshow] = useState(false);
  const handleshow = () => {
    setshow(true);
  };
  const navigate = useNavigate();
  const handleclose = () => {
    setshow(false);
    setTimeout(() => {
      navigate('/dashboard/attemptedseries');
    }, 1400);
  };

  return (
    <>
      {/* begin::Row */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        {/* begin::Col */}
        <div>
          {/* add tests question here */}
          {/* <CardsWidget17 className="abc" /> */}
          {/* <Dashboardcarousel /> */}
          {/* <div
            className="filterbuttonparentdiv"
            style={{ marginTop: "-20px !important" }}
          >
            <SubmittedFilter />
          </div> */}
        </div>
        <div className="seriesContainer2">
          {fetchdata?.result?.map((each: any, index: any) => {
            
            return (
              <>
                {/* {console.log(each)} */}
                <div key={index} style={{ marginRight: "10px", marginBottom: "10px" }}>
                  <CardsWidget21
                    className="abc"
                    view_remarks_description={each?.view_remarks_description}
                    description={each?.test_description}
                    color="rgb(58, 51, 53)"
                    title={each?.test_name}
                    createdat={each?.created_at}
                    duration={each?.duration}
                    scheduled={each?.schedule_datetime}
                    img={""}
                    testid={each?.assesment_id}
                    test_settings={each?.test_settings}
                    totalattempted={each?.user_answers}
                    resultstatus={each?.result_declare}
                    outof={each?.display_questions}
                    istestseries={each?.multiple_time}
                    isdownload={each?.isresultdownloadable}
                    score={each?.marks_obtained}
                    remarks={each?.remarks}
                    testtakersessionid={each?.test_taker_session_id}
                    remarks_description={each?.remarks_description}
                    total_marks={each?.test_total_marks}
                    question_sort_no={each?.question_sort_no}
                    alloted_questions={each?.alloted_questions}
                    company_name={each?.organization_name}
                    company_logo={each?.organization_logo}
                    rating={each?.rating}
                  // test_started_at={each?.created_at}
                  />
                </div>
              </>
            );
          })}
          {fetchdata?.result?.length <= 0 ||
            !fetchdata ||
            (fetchdata?.success == false && (
              <>
                <div style={{ marginTop: "-40px" }}>
                  <p style={{ color: "red" }}>No submitted data found</p>
                </div>
              </>
            ))}
        </div>
        {/* end::Col */}
        <div className="submitdiv">{/* </Online> */}</div>
      </div>
      {/* <Online> */}
      {/* <SubmitModal handleClose={handleClose} show={show} submithandler={() => { submithandler(1) }} /> */}
      {/* <Offline>
        <Snackbar
          open={true}
          autoHideDuration={6000}
        // onClose={handleClose}
        // action={action}
        >
          <Alert variant="filled" severity="error">
            You are currently offline please turn on your internet else your
            exam won't be submitted
          </Alert>
        </Snackbar>
      </Offline> */}

      {/* end::Row */}

      {/* begin::Row */}
      <div className="row gx-5 gx-xl-10">
        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10">
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}
      </div>
      <FeedbackForm show={show}
        handleClose={handleclose} testid={searchParams.get('testid')} />
      {/* end::Row */}
      {/* {fetchedsetting?.randomphotos && <Camerafortest isready={isready} />} */}
    </>
  );
};

const SubmittedTest: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "Attempted Test" })}
      </PageTitle>

      <Testpage />
    </>
  );
};

export { SubmittedTest };
