import { useState, useEffect, useRef } from 'react';
import { logDisconnection } from '../logInternetDisconnectedToIndexDB';

const useNetworkStatus = (modalsRef, id) => {
  const [connectionInfo, setConnectionInfo] = useState({
    isOnline: navigator.onLine,
    effectiveType: navigator.connection?.effectiveType || 'Unknown',
  });

  const disconnectStartTime = useRef(null);

  const updateNetworkInfo = () => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    setConnectionInfo({
      isOnline: navigator.onLine,
      effectiveType: connection?.effectiveType || 'Unknown',
    });

    if (navigator.onLine) {
      modalsRef.current.online = 0;
      if (disconnectStartTime.current !== null) {
        console.log("🚀🚀 reached here")
        const disconnectEndTime = new Date().getTime(); // Record end time of disconnection
        logDisconnection(disconnectStartTime.current, disconnectEndTime, id); // Log the interval
        disconnectStartTime.current = null; // Reset the start time
      }
    }else{
      modalsRef.current.online = 1;
      disconnectStartTime.current = new Date().getTime();
    }
  };

  useEffect(() => {
    updateNetworkInfo(); // Initial update

    // Listen for online/offline events
    const handleOnline = () => updateNetworkInfo();
    const handleOffline = () => updateNetworkInfo();

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Listen for changes in connection
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
      connection.addEventListener('change', updateNetworkInfo);
    }

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (connection) {
        connection.removeEventListener('change', updateNetworkInfo);
      }
    };
  }, []);

  return connectionInfo;
};

export default useNetworkStatus;
