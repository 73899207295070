import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { IoMdCamera, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../Redux/hooks";
import CameraForPreRequsitiesModal from "../modals/CameraForPreRequsitiesModal";
import AntiCheatTermsModal from "../modals/AntiCheatTermsModal";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { StyledTypography } from "../../../../utils/StyledTypographyHeadings";
import PermissionMessage from "./Permissions_Allow/PermissionMessage";

type CameraCompType = {
  setScreenComp: React.Dispatch<React.SetStateAction<boolean>>;
  setCameraComp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOtherComp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTermsComp: React.Dispatch<React.SetStateAction<boolean>>;
  testid?: string;
  tokenid?: string;
  fullscreentogglehandler: () => void;
}

const ShareCamera = ({
  setScreenComp,
  setCameraComp,
  setShowOtherComp,
  setShowTermsComp,
  testid,
  tokenid,
  fullscreentogglehandler,
}: CameraCompType) => {
  const [nextvisiblity, setnextvisbility] = React.useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  const [showCameraModal, setShowCameraModal] = React.useState(false);

  const [userFacesDetected, setUserFacesDetected] = React.useState(0);

  const [showCameraTermsModal, setShowCameraTermsModal] = React.useState(false);

  const handleShowCameraTermsModal = () => {
    setShowCameraTermsModal(true);
  };

  const [initialCameraPermission, setInitialCameraPermission] =
    React.useState(false);

  const showCameraError = React.useRef(false);

  const askcamerapermission = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        setnextvisbility(1);
        setInitialCameraPermission(true);
        setShowCameraModal(true);
      });
  };

  React.useEffect(() => {
    if (
      (userFacesDetected === 0 || userFacesDetected > 1) &&
      initialCameraPermission === true &&
      showCameraModal === false
    ) {
      showCameraError.current = true;
      setnextvisbility(0);
    } else {
      showCameraError.current = false;
    }
  }, [userFacesDetected, dispatch, initialCameraPermission, showCameraModal]);

  const handleBackdropClick = (event: any) => {
    event.stopPropagation();
  };

  const showScreenAndAudioTerm =
    fetchedoverviewdata?.setting?.anti_cheating == "true" &&
    fetchedoverviewdata?.setting?.allowscreenrecording == "true";

  const handleNext = () => {
    if (
      showScreenAndAudioTerm ||
      fetchedoverviewdata?.setting?.live_proctoring_screen == "true"
    ) {
      setScreenComp(true);
      setCameraComp(false);
    } else if (fetchedoverviewdata?.setting?.islocation == "true") {
      setShowOtherComp(true);
      setCameraComp(false);
    } else {
      console.log("Navigating to test page...");
      if (tokenid) {
        navigate(`/preview-test/${tokenid}`);
      } else if (testid) {
        navigate(`/test/${testid}`);
      }
      if (fetchedoverviewdata?.setting?.fullscreen === "true") {
        fullscreentogglehandler();
      }
      setCameraComp(false);
      setShowTermsComp(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          padding: 2,
          width: '100%',
        }}
      >
        <Box
          component="img"
          src={toAbsoluteUrl("/media/camera_share.png")}
          alt="camera-required-image"
          sx={{
            width: {xs: 280, sm: 500},
            height: 250,
            objectFit: "contain",
          }}
        />

        {/* <Typography variant="h5" sx={{ fontWeight: 600, mt: 2 }}>
          Please click on below button to share your camera
        </Typography> */}

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-start",
            alignItems: "center",
            ml: 1,
          }}
        >
          <button
            className="prerequisite-buttons"
            onClick={() => {
              askcamerapermission();
            }}
          >
            Click to allow camera
            <IoMdCamera size={20} />
          </button>
          {nextvisiblity ? (
            <IoMdCheckmark size={30} color="green" />
          ) : (
            <IoMdClose size={30} color="red" />
          )}
        </Box>

        {showCameraError.current === true && (
          <StyledTypography
            variant="body1"
            sx={{ textAlign: 'center', color: "red", width: "100%", marginLeft: 2 }}
          >
            Your face is not detected properly or multiple faces detected,
            please check again by clicking on allow camera button to proceed
            further
          </StyledTypography>
        )}

        <StyledTypography variant="body1" sx={{ fontSize: { xs: 10, sm: 16, xl: 18 } }}>
          By clicking on above button you agree the
          <StyledTypography
            component="span"
            variant="body1"
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              marginLeft: 1,
              fontSize: { xs: 10, sm: 16, xl: 18 },
            }}
            onClick={() => {
              handleShowCameraTermsModal();
            }}
          >
            terms and conditions for camera capture
          </StyledTypography>
        </StyledTypography>

        <PermissionMessage />
        <button
          className={`styled-btn ${nextvisiblity === 0 ? "disabled" : ""}`}
          onClick={handleNext}
          disabled={nextvisiblity === 0}
        >
          Next
        </button>
      </Box>

      {(fetchedoverviewdata?.setting?.randomphotos == "true" ||
        fetchedoverviewdata?.isvideoanswer ||
        fetchedoverviewdata?.setting?.live_proctoring_camera == "true") &&
        nextvisiblity === 1 && (
          <Modal
            open={showCameraModal}
            onClose={() => setShowCameraModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            componentsProps={{
              backdrop: {
                onClick: handleBackdropClick,
              },
            }}
          >
            <CameraForPreRequsitiesModal
              setModalVisible={setShowCameraModal}
              setUserFacesDetected={setUserFacesDetected}
            />
          </Modal>
        )}

      <AntiCheatTermsModal
        type={3}
        showModal={showCameraTermsModal}
        setShowModal={setShowCameraTermsModal}
      />
    </>
  );
};

export default ShareCamera;
