import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../Redux/hooks";
import { Button } from "react-bootstrap";
import { ReportModal } from "../../../../partials/widgets/_new/modal/ReportModal";
import { useState } from "react";
import { Report } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { toAbsoluteUrl } from "../../../../helpers";
import { HeadingsTypography, StyledTypography } from "../../../../../utils/StyledTypographyHeadings";

export function MenuInner() {
  const location = useLocation();
  const intl = useIntl();
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  const [openmodal, setopenmodal] = useState(false);
  const openReportModal = () => {
    setopenmodal(true);
  }
  const closeReportModal = () => {
    setopenmodal(false);
  }



  return (
    <div style={{ position: "absolute", left: "10px", top: "8px" }}>
      {(location.pathname.slice(1, 5) == "test" ||
        location.pathname.includes("preview")) && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <HeadingsTypography variant="h5">{fetchedoverviewdata?.testname}</HeadingsTypography>
                <Tooltip title="Report this test if you find anything inappropriate or wrong with it">
                  <button onClick={openReportModal} className="btn2" style={{ marginBottom: '4px' }}>
                    <Report fontSize="medium" style={{ marginRight: "1px" }} /> Report
                    this test
                  </button>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <StyledTypography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    letterSpacing: "1px",
                    fontSize: { xs: 8, sm: 12, xl: 14 },
                    color: "#8f8f8f",
                  }}
                >
                  Conducted by:
                </StyledTypography>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
                  <Box
                    component="img"
                    src={
                      fetchedoverviewdata?.organization_logo
                        ? `${process.env.REACT_APP_CREATOR_URL}/${fetchedoverviewdata.organization_logo}`
                        : toAbsoluteUrl("/media/avatars/placeholder_img.png")
                    }
                    alt="Company Logo"
                    sx={{
                      width: 28,
                      height: 28,
                      borderRadius: '50%',
                      objectFit: fetchedoverviewdata?.organization_logo ? "contain" : "cover",
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    }}
                  />
                  <StyledTypography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      letterSpacing: "1px",
                      fontSize: { xs: 8, sm: 12, xl: 14 },
                    }}
                  >
                    {fetchedoverviewdata?.conductedby ?? ""}
                  </StyledTypography>
                </Box>

              </Box>

            </Box>


          </>
        )}
      {!(
        location.pathname.slice(1, 5) == "test" ||
        location.pathname.includes("preview")
      ) && (
          <MenuItem
            title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
            to="/home/dashboard"
          />
        )}

      <ReportModal
        type={2}
        questionId={fetchedoverviewdata?.testid}
        open={openmodal}
        onClose={closeReportModal}
      />
    </div>
  );
}
